import React from "react"
import styled from "styled-components"

// import app components
import Edges from "../components/edges"
import { CartContent } from "../components/cart/Cart"

export default function cartPage() {
  return (
    <Container>
      <Edges size="lg">
        <CartContent />
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  padding: 30px 0;
`
